<template>
  <div>
    <BulleDeco positionY="-150" />
    <div class="pb-10 mh-300 container ml-auto mr-auto pl-5 pr-5 blob-fear">
      <div class="col-start-2 col-span-2 slogan">
        Conditions générales d'utilisations
      </div>

      <div class="col-start-2 col-span-2 text-center">
        <div class="lds-ring" id="md" v-if="page == ''">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="col-start-2 col-span-2 sous_menu gap-4 sous_menu_content">
        <!--router-view :name="selectedSousMenu.routeName" /-->
        <div v-html="page" class="page z-10"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
//import sousMenuData from "../components/b2b6/sousMenu.data.js";
import BulleDeco from "../../components/public/B2b6.bulle_deco.vue";
import axios from "axios";

export default defineComponent({
  name: "B2b4",
  components: {
    BulleDeco,
  },
  data() {
    return {
      page: "",
    };
  },
  mounted() {
    const self = this;
    axios({
      method: "GET",
      url: "https://api.exval.fr/api/v1/static-page/general-params?link=cgu",
    })
      .then(function (response) {
        self.page = response.data.data;
      })
      .catch(function (err) {
        console.log(err);
      });
  },
});
</script>

<style scoped>
.sous_menu_content {
  justify-content: center;
  display: flex;
}
.hez {
  width: 100%;
  display: block;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: 0;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  margin: 8px;
  border: 2px solid #383366;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #383366 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mh-300 {
  min-height: 300px;
}
.pb-10 {
  padding-bottom: 30px !important;
}
.CTA-Big-1 label {
  display: none;
}
.slogan {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 10px;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -1px;
  text-align: center;
  color: #363660;
}
.blob-fear {
  position: relative;
}
</style>