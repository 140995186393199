<template>
  <div
    class="deco hidden lg:block"
    :style="'transform: translateY(' + positionY + 'px)'"
  >
    <div class="deco0 deco_1"></div>
    <div class="deco0 deco_2"></div>
    <div class="deco0 deco_3"></div>
    <div class="deco0 deco_4"></div>
    <div class="deco0 deco_5"></div>
    <div class="deco0 deco_6"></div>
    <div class="deco0 deco_7"></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "B2b4",
  components: {},
  props: {
    positionY: {
      type: String,
      default: "0",
    },
  },
});
</script>

<style scoped>
.deco {
  position: absolute;
}

.deco0 {
  position: absolute;
  z-index: 0;
  border-radius: 100px;
  box-shadow: 0 2px 4px 0 rgba(96, 97, 112, 0.16);
  background-color: #ffffff;
}

.deco_1 {
  width: 55px;
  height: 30px;
  transform: translate(224px, 206px);
}

.deco_2 {
  width: 75px;
  height: 42px;
  transform: translate(71px, 246px);
}

.deco_3 {
  width: 124px;
  height: 68px;
  transform: translate(146px, 302px);
}

.deco_4 {
  width: 55px;
  height: 30px;
  transform: translate(85vw, 221px);
}

.deco_5 {
  width: 55px;
  height: 30px;
  transform: translate(77vw, 252px);
}

.deco_6 {
  width: 124px;
  height: 68px;
  transform: translate(81vw, 288px);
}

.deco_7 {
  width: 55px;
  height: 30px;
  transform: translate(88vw, 384px);
}
</style>

<style>
.test {
  border: 2px solid red;
}
</style>